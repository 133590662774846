//TODO: Позже, когда будет готов сервер, возможно, типы переименуются]
import { TFacadeErrorObject } from './errors';
import { TCustomer } from './customer';

export type TPayoutResponse = TPayoutDto;

export type TPayoutMethod = TApmPayoutMethod | 'cards' | 'payster';

export type TApmPayoutMethod =
  | 'payid'
  | 'payster'
  | 'interac'
  | 'payfix'
  | 'interaccashout'
  | 'interactransfer'
  | 'communitybank'
  | 'sepainstanttfeur'
  | 'paysterfmaud'
  | 'wiretransferfmnzd';

export type TPayoutPayidType = 'email' | 'phone' | 'bsbAccount';

export type TPayoutScreenId = (typeof availablePayoutScreenIdList)[number];

export const availablePayoutPaysterScreenIdList = [
  'payoutPaysterWalletSignIn',
  'payoutPaysterPending',
  'payoutPaysterSuccess',
  'payoutPaysterFailure',
  'payoutPaysterError',
  'payoutPaysterFmAudMethodSelection',
  'payoutWireTransferFmNzdMethodSelection',
] as const;

export const availablePayoutDefaultScreenIdList = [
  'payoutMethodSelection',
  'payoutPending',
  'payoutSuccess',
  'payoutFailure',
  'payoutError',
] as const;

export const availablePayoutScreenIdList = [
  ...availablePayoutPaysterScreenIdList,
  ...availablePayoutDefaultScreenIdList,
];

type TFieldSetting = { hide: boolean };
export type TMethodSettings<T extends string = string> = {
  fields?: Partial<Record<T, TFieldSetting>>;
};

export type TPayoutSettings<T extends string = string> = {
  methods: Partial<Record<TPayoutMethod, TMethodSettings<T>>>;
};

export type TPayoutDto = {
  screenId: TPayoutScreenId;
  order?: TPayoutOrderDto;
  backToMerchantUrl?: string;
  methods?: TPayoutMethod[];
  customer?: TCustomer | null;
  error?: TFacadeErrorObject;
  settings?: TPayoutSettings;
};

export type TPayoutOrderDto = {
  id: string;
  amount: number;
  currency: string;
};

export type TCreateApmPayoutParams = {
  method: TApmPayoutMethod;
  details:
    | TPayIdDetails
    | TInteracCashoutDetails
    | TInteracTransferDetails
    | TPaysterPayIdDetails
    | TPayfixDetails
    | TCommunityBankDetails
    | TSepaPayoutDetails
    | TWireTransferPayoutDetails;
};

//region details types
type TPayIdDetails = {
  type?: TPayoutPayidType;
  email?: string;
  phone?: string;
  bsb?: string;
  bsbAccount?: string;
  accountHolder?: string;
};

type TInteracCashoutDetails = {
  type: 'bankAccount';
  accountNumber: string;
  transitNumber: string;
  financialInstitutionNumber: string;
};

type TInteracTransferDetails = {
  type: 'email';
  email: string;
};

type TPayfixDetails = {
  type: 'wallet';
  walletId: string;
};

type TCommunityBankDetails = {
  type: 'bankAccount';
  iban: string;
  swiftCode: string;
  beneficiaryName: string;
  documentNumber: string;
};

export type TCardPayoutDetails = {
  cardNumber: string;
  cardHolderName: string;
  expiryMonth: number;
  expiryYear: number;
  cardSecurityCode?: string;
};

export type TSepaPayoutDetails = {
  type: 'bankAccount';
  beneficiaryFirstName: string;
  beneficiaryLastName: string;
  beneficiaryBirthdate: string;
  beneficiaryEmail: string;
  beneficiaryCountryResidence: string;
  iban: string;
};

export type TWireTransferPayoutDetails = {
  beneficiaryFirstName: string;
  beneficiaryLastName: string;
  accountNumber: string;
};

type TPaysterPayIdDetails = TPayIdDetails & { walletId: string };

//endregion
