import React, { forwardRef } from 'react';
import { Input, Text } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { TDropdownOption } from './types';

export type TDropdownSearchProps = {
  onChange: (e: string) => void;
  searchText: string;
  selectedOption?: TDropdownOption;
  placeholder?: string;
  isMulti?: boolean;
  isDisabled?: boolean;
  floatingLabel?: string;
  hideClearButton?: boolean;
  autocomplete?: string;
  testId?: string;
};

export const DropdownSearch = forwardRef<
  HTMLInputElement,
  TDropdownSearchProps
>((props, ref) => {
  const textColor = getTextColor(props);
  return (
    <>
      {!props.searchText && props.selectedOption?.label && (
        <Text
          transitionDuration="fast"
          textStyle={TextStyles.BodyUI16Regular}
          position="absolute"
          top={props.floatingLabel ? '24px' : '12px'}
          left="16px"
          color={textColor}
          noOfLines={1}
          maxW={`calc(100% - ${props.hideClearButton ? 64 : 86}px)`}
          data-testid={`${props.testId}-selected-option-label`}
        >
          {props.selectedOption?.label}
        </Text>
      )}
      <Input
        ref={ref}
        type="text"
        placeholder={props.placeholder}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        value={props.searchText}
        variant="unstyled"
        padding={
          props.floatingLabel
            ? props.isMulti
              ? '0 10px'
              : '24px 0 8px'
            : '0 0'
        }
        borderRadius="0"
        flex={1}
        // minWidth="200px"
        isDisabled={props.isDisabled}
        autoComplete={props.autocomplete}
        data-testid={`${props.testId}-search-input`}
      />
    </>
  );
});

function getTextColor(props: TDropdownSearchProps) {
  if (props.isDisabled) {
    return 'primary.350';
  }
  const textIsPlaceholder =
    !!props.selectedOption && props.placeholder && !props.floatingLabel;
  return textIsPlaceholder ? 'primary.350' : 'primary.500';
}
