import { useCallback, useMemo } from 'react';
import { useApi } from '../../context/config-context';
import { assert } from '@payler/utils';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  TCardPayoutDetails,
  TCreateApmPayoutParams,
  TPayoutMethod,
  TPayoutOrderDto,
  TPayoutResponse,
  TMethodSettings,
} from '@payler/payment-page-api-gate';
import { useIdFromUrl } from '@payler/payment-page-ui-shared';
import createLogger from 'debug';

const log = createLogger('Payout hooks');

export const usePayoutQuery = () => {
  const api = useApi();
  const sessionId = useIdFromUrl();
  assert(sessionId, 'Payout id is required');
  return useQuery({
    queryKey: ['payout'],
    queryFn: () => api.getPayout(sessionId),
    refetchOnWindowFocus: false,
    staleTime: 600_000,
    structuralSharing: false,
  });
};

const useCreateApmPayoutMutation = () => {
  const api = useApi();
  const id = useIdFromUrl();
  assert(id, 'Payout id is required');
  return useMutation({
    mutationFn: async (params: TCreateApmPayoutParams) => {
      return await api.createApmPayout(id, params);
    },
  });
};

const usePatchPayout = () => {
  const client = useQueryClient();
  const sessionId = useIdFromUrl();
  assert(sessionId, 'Payout id is required');
  return (payoutData: TPayoutResponse) =>
    client.setQueryData<TPayoutResponse>(['payout'], (prevPayoutData) => ({
      ...prevPayoutData,
      ...payoutData,
    }));
};

export const usePayout = () => {
  const { isLoading, isError, data, dataUpdatedAt } = usePayoutQuery();

  return useMemo(
    () => ({
      payout: data,
      isLoading,
      isError,
      dataUpdatedAt,
    }),
    [data, isLoading, isError, dataUpdatedAt],
  );
};

export const useInvalidatePayoutQuery = () => {
  const sessionId = useIdFromUrl();
  assert(!!sessionId, 'Session id can not be empty or undefined');
  const clientQuery = useQueryClient();

  return useCallback(async () => {
    if (!clientQuery.isFetching({ queryKey: ['payout'] })) {
      await clientQuery.invalidateQueries({ queryKey: ['payout'] });
    }
  }, [clientQuery]);
};

export const usePayoutScreenId = () => {
  const { payout } = usePayout();
  return payout?.screenId;
};

export const usePayoutMethods = () => {
  const { payout } = usePayout();
  return useMemo(
    () =>
      payout?.methods?.reduce(
        (acc: Record<TPayoutMethod, boolean>, method: TPayoutMethod) => {
          acc[method] = true;

          return acc;
        },
        {} as Record<TPayoutMethod, boolean>,
      ) || ({} as Record<TPayoutMethod, boolean>),
    [payout?.methods],
  );
};

export const usePayoutOrder = (): TPayoutOrderDto => {
  const { payout } = usePayout();

  return payout?.order || ({} as TPayoutOrderDto);
};

export const usePayoutMerchantUrl = () => {
  const { payout } = usePayout();
  return payout?.backToMerchantUrl;
};

export const useCreateApmPayout = () => {
  const patchPayout = usePatchPayout();
  const {
    mutate: createApmPayoutMutation,
    isError,
    isPending: isLoading,
  } = useCreateApmPayoutMutation();

  const createApmPayout = useCallback(
    (params: TCreateApmPayoutParams) => {
      createApmPayoutMutation(params, {
        onSuccess: (data) => {
          log('patch payout session after apm payout %o', data);
          patchPayout(data);
        },
      });
    },
    [createApmPayoutMutation, patchPayout],
  );

  return useMemo(
    () => ({ createApmPayout, isError, isLoading }),
    [createApmPayout, isError, isLoading],
  );
};

const useCreateCardPayoutMutation = () => {
  const api = useApi();
  const id = useIdFromUrl();
  assert(id, 'Payout id is required');
  return useMutation({
    mutationFn: async (params: TCardPayoutDetails) => {
      return await api.createCardPayout(id, params);
    },
  });
};

export const useCreateCardPayout = () => {
  const patchPayout = usePatchPayout();
  const {
    mutate: createCardPayoutMutation,
    isError,
    isPending: isLoading,
  } = useCreateCardPayoutMutation();
  const createCardPayout = useCallback(
    (params: TCardPayoutDetails) => {
      createCardPayoutMutation(params, {
        onSuccess: (data) => {
          log('patch payout session after card payout %o', data);
          patchPayout(data);
        },
      });
    },
    [createCardPayoutMutation, patchPayout],
  );

  return useMemo(
    () => ({ createCardPayout, isError, isLoading }),
    [createCardPayout, isError, isLoading],
  );
};

export const usePayoutCustomerData = () => {
  const { payout } = usePayout();

  return payout?.customer;
};

export const usePayoutMethodFieldsSettings = <T extends string = string>(
  method: TPayoutMethod,
) => {
  const { payout } = usePayout();

  return (payout?.settings?.methods?.[method]?.fields || {}) as Required<
    TMethodSettings<T>
  >['fields'];
};

export const useManualRejectPayout = () => {
  const api = useApi();
  const id = useIdFromUrl();
  assert(id, 'Payout id is required');

  const { mutate, isPending } = useMutation({
    mutationFn: () => api.manualRejectPayout(id),
  });

  const manualReject = useCallback(
    (options: UseMutationOptions) => {
      mutate(undefined, options);
    },
    [mutate],
  );

  return { manualReject, isManualRejectPending: isPending };
};
