import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Popover,
  PopoverContent,
  PopoverContentProps,
  PopoverTrigger,
  Portal,
  StackProps,
  useControllableState,
  useDisclosure,
} from '@chakra-ui/react';
import ScrollBar from 'react-perfect-scrollbar';
import { debounce } from 'lodash';
import { DropdownOptions } from './DropdownOptions';
import { DropdownTrigger } from './DropdownTrigger';
import { TDropdownOption } from './types';
import { defaultPopoverProps, popoverContentVariants } from './const';
import { DropdownSearch } from './DropdownSearch';
import { useTranslation } from 'react-i18next';

export type TDropdownProps = {
  /**
   * Опции выпадающего списка
   * Если есть опция со значением null - она показывается при невыбранном значении
   */
  options: TDropdownOption[];
  /**
   * Плавающий заголовок поля (Если есть то Type2, если нет то Type1)
   */
  floatingLabel?: string;
  /**
   * значение (не использовать undefined!)
   */
  value?: string | number | null;
  /**
   * Лейбл для предустановленного значения, на случай если его нет в списке (например находится
   * на другой странице при постраничной подгрузке)
   */
  valueLabel?: string;
  /**
   * Обработчик при выборе значения
   * @param value
   */
  onChange?: (value: string | number | null) => void;
  /**
   * Выключен
   */
  isDisabled?: boolean;
  /**
   * Ошибка
   */
  isError?: boolean;
  /**
   * Доп. стили для PopoverContent
   */
  popoverContentProps?: PopoverContentProps;
  /**
   * не обрезать длинное значение в триггере, а переносить на новую строку
   */
  noEllipsis?: boolean;
  /**
   * Скрывать кнопку очищения
   */
  hideClearButton?: boolean;
  /**
   * Показывать когда значение не выбрано (в Type 1 - не floating)
   */
  placeholder?: string;
  /**
   * При потере фокуса
   */
  onBlur?: VoidFunction;
  /**
   * Пропсы обёртки триггера
   */
  triggerWrapperProps?: Omit<StackProps, 'borderColor'>;
  /**
   * Выравнивать текст опций по центру
   */
  alignOptionsCenter?: boolean;
  /**
   * Отображать поле Поиска?
   */
  isSearchable?: boolean;
  /**
   * Обработчик при изменении значения в поле поиска
   */
  onSearchTextChange?: (value: string) => void;
  /**
   * Время задержки при изменеии значения в поле поиска (по умолчанию 250 миллисекунд)
   */
  onSearchTextChangeDelay?: number;
  /**
   * Подсказки браузера при заполнении формы
   */
  autocomplete?: string;
  /**
   * Атрибут для поиска элемента в автотестах
   */
  testId?: string;
  /**
   * Отображение индикатора загрузки
   */
  isLoading?: boolean;
  /**
   * Реф бесконечного лоадера в дропдауне
   */
  infiniteScroll?: {
    isLoading: boolean;
    ref: React.LegacyRef<HTMLDivElement>;
  };
};

export const Dropdown = (props: TDropdownProps) => {
  const { t } = useTranslation();
  const didMountRef = useRef(false);
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const searchFieldRef = useRef<HTMLInputElement | null>(null);
  const scrollBarRef = useRef<HTMLElement | null>(null);

  const {
    onBlur: outerOnBlur,
    onSearchTextChange,
    onSearchTextChangeDelay,
    testId = 'dropdown',
  } = props;
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure();
  const [searchList, setSearchList] = useState<TDropdownOption[]>(
    props.options,
  );

  const [isFocused, setIsFocused] = useState(
    !props.isDisabled && triggerRef.current === document.activeElement,
  );
  const [searchText, setSearchText] = useState<string>('');
  const [value, setValue] = useControllableState({
    value: props.value,
    onChange: props.onChange,
    defaultValue: null,
  });
  const [valueLabel, setValueLabel] = useState(props.valueLabel);

  const handleFocus = useCallback(() => {
    if (!props.isDisabled) {
      setIsFocused(true);
    }
  }, [props.isDisabled]);

  const handleBlur = useCallback(() => {
    if (!props.isDisabled) {
      setIsFocused(false);
    }
  }, [props.isDisabled]);

  const debounceOnSearchChange = useMemo(
    () =>
      debounce((val: string) => {
        if (onSearchTextChange) {
          onSearchTextChange(val);
        }
      }, onSearchTextChangeDelay || 250),
    [onSearchTextChange, onSearchTextChangeDelay],
  );

  const handleSearchChange = useCallback(
    (val: string) => {
      setSearchText(val);
      if (onSearchTextChange) {
        debounceOnSearchChange(val);
      }
    },
    [setSearchText, onSearchTextChange, debounceOnSearchChange],
  );

  useEffect(() => {
    if (didMountRef.current && !isFocused && !isOpen) {
      outerOnBlur?.();
    }
  }, [isFocused, isOpen, outerOnBlur]);

  const handleOptionClick = useCallback(
    (val: string | number) => {
      setValue(val);
      setValueLabel(
        props.options.find((option) => option.value === val)?.label,
      );
      handleSearchChange('');
      onClose();
    },
    [onClose, setValue, setValueLabel, handleSearchChange, props.options],
  );

  const selectedOption = useMemo(() => {
    const option: TDropdownOption | undefined = props.options.find(
      (x) => x.value === value,
    );
    if (!option && value) {
      return {
        label: valueLabel || props.valueLabel || String(value),
        value,
      };
    }
    return option;
  }, [props.options, value, valueLabel, props.valueLabel]);

  const handleClear = useCallback(() => {
    setValue(null);
    onClose();
    handleSearchChange('');
    setIsFocused(false);
  }, [onClose, setValue, handleSearchChange, setIsFocused]);

  const dropdownSelectedValues = useMemo(() => {
    return value === null ? null : [value];
  }, [value]);

  const filterOptionsList = useCallback(() => {
    const filteredList = props.options.filter((option) =>
      option.label.toLowerCase().includes(searchText),
    );
    setSearchList(filteredList);
  }, [props.options, searchText]);

  useEffect(() => {
    filterOptionsList();
    if (searchText) {
      onOpen();
    }
  }, [searchText, filterOptionsList, onOpen]);

  const closeDropdownWithResetSearch = useCallback(() => {
    onClose();
    handleSearchChange('');
    setIsFocused(false);
    searchFieldRef?.current?.blur();
  }, [onClose, handleSearchChange, setIsFocused, searchFieldRef]);

  const handleClick = useCallback(
    (e: Event) => {
      if (
        triggerRef.current &&
        !triggerRef.current.contains(e?.target as Node) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e?.target as Node)
      ) {
        closeDropdownWithResetSearch();
      }
    },
    [closeDropdownWithResetSearch],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.key === 'Esc') {
        closeDropdownWithResetSearch();
      }
    },
    [closeDropdownWithResetSearch],
  );

  useEffect(() => {
    if (isOpen && props.isSearchable) {
      window.addEventListener('mousedown', handleClick);
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('mousedown', handleClick);
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, [isOpen, handleClick, handleKeyDown, props.isSearchable]);

  useEffect(() => {
    didMountRef.current = true;
  }, []);

  return (
    <Popover
      matchWidth
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom-start"
      variant="dropdown"
      isLazy
      lazyBehavior="unmount"
      initialFocusRef={props.isSearchable ? searchFieldRef : undefined}
    >
      <PopoverTrigger>
        <Box
          userSelect="none"
          ref={triggerRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onClick={props.isDisabled ? undefined : onToggle}
          position="relative"
          outline="none"
          tabIndex={0}
          data-testid={testId}
        >
          <DropdownTrigger
            hideClearButton={props.hideClearButton}
            noEllipsis={!!props.noEllipsis}
            onClear={handleClear}
            floatingLabel={props.floatingLabel}
            selectedOption={selectedOption}
            isOpen={isOpen}
            isDisabled={props.isDisabled ?? false}
            isError={props.isError ?? false}
            isActive={isFocused || isOpen || !!searchText}
            placeholder={props.placeholder}
            triggerWrapperProps={props.triggerWrapperProps}
            isFocused={isFocused}
            isSearchable={props.isSearchable}
            isLoading={props.isLoading}
            searchField={
              <DropdownSearch
                ref={searchFieldRef}
                onChange={(val) => handleSearchChange(val.toLowerCase())}
                searchText={searchText}
                placeholder={props.placeholder}
                selectedOption={selectedOption}
                isDisabled={props.isDisabled ?? false}
                floatingLabel={props.floatingLabel}
                hideClearButton={props.hideClearButton}
                autocomplete={props.autocomplete}
                testId={testId}
              />
            }
          />
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          variants={popoverContentVariants}
          {...defaultPopoverProps(searchList)}
          {...props.popoverContentProps}
          ref={dropdownRef}
          data-testid={`${testId}-body`}
        >
          <ScrollBar
            containerRef={(el) => (scrollBarRef.current = el)}
            options={{ suppressScrollX: true, wheelPropagation: true }}
          >
            {searchList.length ? (
              <DropdownOptions
                options={searchList}
                selected={dropdownSelectedValues}
                onOptionClick={handleOptionClick}
                alignOptionsCenter={props.alignOptionsCenter}
                isSearchable={props.isSearchable}
                testId={testId}
                infiniteScroll={props.infiniteScroll}
              />
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={2}
                data-testid={`${testId}-no-options`}
              >
                {t('dropdown.noOptions', 'No options')}
              </Box>
            )}
          </ScrollBar>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default Dropdown;
