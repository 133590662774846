import { TextStyles } from '@payler/ui-theme';
import { Text } from '@chakra-ui/react';
import * as React from 'react';

type TFloatingLabelProps = {
  label?: string;
  hasValue: boolean;
  isDisabled?: boolean;
  hideClearButton?: boolean;
};

export const FloatingLabel = ({
  label,
  hasValue,
  isDisabled,
  hideClearButton,
}: TFloatingLabelProps) => {
  const textStyle = hasValue
    ? TextStyles.Caption12Regular
    : TextStyles.BodyUI16Regular;
  const textColor = getTextColor(isDisabled, hasValue);
  const top = hasValue ? 1 : 2;
  return (
    <Text
      color={textColor}
      transitionDuration="fast"
      as="span"
      textStyle={textStyle}
      transitionProperty="all"
      position="absolute"
      left={2}
      top={top}
      noOfLines={1}
      maxW={`calc(100% - ${hideClearButton ? 64 : 86}px)`}
    >
      {label}
    </Text>
  );
};

function getTextColor(disabled: boolean | undefined, hasValue: boolean) {
  if (disabled) {
    return 'primary.300';
  }
  return 'primary.350';
}
