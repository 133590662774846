import { memo, useEffect } from 'react';
import { useFormVariantContext } from '../../context/form-variant-context';
import { usePayout, usePayoutScreenId } from '../../hooks/payout';
import { FullPageLoader, NotFound } from '@payler/payment-page-ui-shared';
import { Outlet } from 'react-router-dom';
import { availablePayoutPaysterScreenIdList } from '@payler/payment-page-api-gate';

export const PayoutScreen = memo(() => {
  const { setFormVariant } = useFormVariantContext();
  const { isLoading, payout } = usePayout();
  const methodsList = payout?.methods;
  const screenId = usePayoutScreenId();

  useEffect(() => {
    if (
      availablePayoutPaysterScreenIdList.includes(
        screenId as (typeof availablePayoutPaysterScreenIdList)[number],
      )
    ) {
      setFormVariant('payster');
    } else {
      setFormVariant('default');
    }
  }, [setFormVariant, methodsList, screenId]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  if (!payout) {
    return <NotFound />;
  }

  return <Outlet />;
});
